import { Container, Navbar, Dropdown, DropdownButton } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../api/auth";
import wemintLogo from "../components/assets/logo.svg";

const Header = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const loggedIn = () => {
    return (
      <Container>
        <Navbar>
          <Container>
            <Navbar.Brand onClick={() => navigate("/")}>
              <img
                alt=""
                src={wemintLogo}
                width=""
                height="45"
                className="mb-2"
              />
            </Navbar.Brand>
            <Navbar.Text></Navbar.Text>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                Signed in as: {auth.user!.fullName}
                &nbsp;{" "}
              </Navbar.Text>
              &nbsp;
              <DropdownButton
                variant="outline-secondary"
                size="sm"
                title="My account">
                {/* <Dropdown.Item eventKey="1">Edit profile</Dropdown.Item> */}
                <Dropdown.Item
                  eventKey="2"
                  onClick={() => {
                    auth.signOut(() => navigate("/"));
                  }}>
                  Log out
                </Dropdown.Item>
              </DropdownButton>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <hr />
      </Container>
    );
  };

  const loggedOut = () => {
    return (
      <Navbar.Collapse className="justify-contend-end">
        <LinkContainer to="/login">
          <Navbar.Text>Log in</Navbar.Text>
        </LinkContainer>
      </Navbar.Collapse>
    );
  };

  return (
    <div id="header">{auth.isAuthenticated ? loggedIn() : loggedOut()}</div>
  );
};

export default Header;
