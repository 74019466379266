import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  useBucketDeleteS3Key,
  useBucketS3Keys,
  useNewBucketS3Key,
} from "../../api/api";
import { BucketS3KeyPermissions } from "../../api/models";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

const Bucket = () => {
  const { name } = useParams<{ name: string }>();

  const {
    isLoading,
    isError,
    data: bucketS3Keys,
    error,
  } = useBucketS3Keys(name!);

  const addKeyMutation = useNewBucketS3Key(name!);
  const deleteKeyMutation = useBucketDeleteS3Key(name!);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>An error has occurred: {error!.message}</div>;

  const addS3Key = (permissions: BucketS3KeyPermissions) => {
    return () => {
      addKeyMutation.mutateAsync({
        permissions,
      });
    };
  };

  const deleteS3Key = (id: number) => {
    return () => {
      deleteKeyMutation.mutateAsync(id);
    };
  };

  return (
    <>
      <Container>
        <h3>Name: {name}</h3>
        <br />
        <Card body>
          <Card.Title>S3 Key management</Card.Title>
          Endpoint: <a href={`https://fr.wmcdn.io`}>fr.wmcdn.io</a>
          <br />
          <br />
          Add a key:{" "}
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={addS3Key(BucketS3KeyPermissions.ReadOnly)}>
            read-only key
          </Button>{" "}
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={addS3Key(BucketS3KeyPermissions.ReadWrite)}>
            read and write key
          </Button>
          <br />
          <br />
          <Table hover borderless size="sm">
            <thead>
              {" "}
              <tr>
                <th>Access key</th>
                <th>Secret key</th>
                <th>Permissions</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {bucketS3Keys!.map((v, i) => (
                <tr key={i}>
                  <td>{v.accessKey}</td>
                  <td>{v.secretKey}</td>
                  <td>{v.permissions}</td>
                  <td>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={deleteS3Key(v.id)}>
                      delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Container>
    </>
  );
};

export default Bucket;
