import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div id="footer" className="container mt-3 my-md-1 pt-md-4">
      <div className="col-12 col-md">
        <small className="d-block mb-3 text-muted">
          &copy; {new Date().getFullYear()} - Wemint
          <span className="float-end">
            {/* <Link className="text-muted" to="#"><FormattedMessage id="footer.status" defaultMessage="status"/></Link> -&nbsp;
                <Link className="text-muted" to="#"><FormattedMessage id="footer.abuse" defaultMessage="abuse"/></Link> -&nbsp; */}
            <Link className="text-muted" to="/beta">
              This service is currently in beta
            </Link>{" "}
            -&nbsp;
            <a className="text-muted" href="https://wemint.net/terms">
              terms & conditions
            </a>{" "}
            -&nbsp;
            <a className="text-muted" href="https://wemint.net/legal">
              legal
            </a>
          </span>
        </small>
      </div>
    </div>
  );
};

export default Footer;
