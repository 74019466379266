import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSignUp } from "../api/api";
import wemintLogo from "../components/assets/logo.svg";

const SignUp = () => {
  const navigate = useNavigate();
  const mutation = useSignUp();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      email: { value: string };
      password: { value: string };
      fullName: { value: string };
      address: { value: string };
      city: { value: string };
      country: { value: string };
      zip: { value: string };
    };

    const signUpInput = {
      email: target.email.value,
      password: target.password.value,
      fullName: target.fullName.value,
      address: target.address.value,
      city: target.city.value,
      country: target.country.value,
      zip: target.zip.value,
    };

    mutation.mutateAsync(signUpInput, {
      onSuccess: () => {
        navigate("/login");
      },
    });
  };

  return (
    <>
      <Container className="form-signup">
        <div className="text-center">
          <img
            className="mb-4 mt-5"
            src={wemintLogo}
            alt="Wemint logo"
            width=""
            height="72"
          />
          <h4 className="mb-4 mt-2">Register your account</h4>
        </div>
        <br />
        <Form onSubmit={handleSubmit}>
          <Form.Group className="form-floating">
            <Form.Control
              name="email"
              type="email"
              placeholder="Email address"
            />
            <Form.Label>Email address</Form.Label>
          </Form.Group>
          <br />
          <Form.Group className="form-floating">
            <Form.Control
              name="password"
              type="password"
              placeholder="Password"
            />
            <Form.Label>Password (minimum 8 characters)</Form.Label>
          </Form.Group>

          <hr className="m-4" />

          <Form.Group className="form-floating">
            <Form.Control
              name="fullName"
              type="text"
              placeholder="Your full name or Company name"
            />
            <Form.Label>Your full name or Company name</Form.Label>
          </Form.Group>
          <br />
          <Form.Group className="form-floating">
            <Form.Control
              name="address"
              type="text"
              placeholder="Address (full street & number"
            />
            <Form.Label>Address (full street & number)</Form.Label>
          </Form.Group>
          <br />
          <Row>
            <Col>
              <Form.Group className="form-floating">
                <Form.Control name="zip" type="text" placeholder="ZIP code" />
                <Form.Label>ZIP code</Form.Label>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-floating">
                <Form.Control
                  name="city"
                  type="text"
                  placeholder="City / State"
                />
                <Form.Label>City / State</Form.Label>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-floating">
                <Form.Control
                  name="country"
                  type="text"
                  placeholder="Country"
                />
                <Form.Label>Country</Form.Label>
              </Form.Group>
            </Col>
          </Row>

          <div className="text-center text-muted small mt-4 mb-4">
            By clicking on Register, I agree to the{" "}
            <a className="text-muted" href="https://wemint.net/terms">
              terms and conditions
            </a>
          </div>

          <div className="text-center small mt-4 mb-4 text-decoration-underline">
            This service is currently in beta.
          </div>

          <Button
            className="w-100 btn btn-lg btn-primary"
            variant="primary"
            type="submit">
            Register
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default SignUp;
