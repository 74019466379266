import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getUser, useSignIn } from "./api";
import { SignInInput, UserInfo } from "./models";

interface AuthContextType {
  user: UserInfo | null;
  isAuthenticated: boolean;
  token: string;
  signIn: (signInInput: SignInInput, callback: VoidFunction) => void;
  signOut: (callback: VoidFunction) => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = useState("");
  const [user, setUser] = useState<UserInfo | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const signInMutation = useSignIn();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const signIn = (signInInput: SignInInput, callback: VoidFunction) => {
    signInMutation.mutateAsync(signInInput, {
      onSuccess: async data => {
        setToken(data.token);
        await getUser(data.token).then(u => {
          setUser(u);
        });
        setIsAuthenticated(true);
        queryClient.invalidateQueries("user");
        localStorage.setItem("token", data.token);
        callback();
      },
    });
  };

  const signOut = (callback: VoidFunction) => {
    setToken("");
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem("token");
    callback();
  };

  useEffect(() => {
    const t = localStorage.getItem("token");

    if (t) {
      getUser(t)
        .then(u => {
          setToken(t);
          setUser(u);
          setIsAuthenticated(true);
          navigate("/");
        })
        .catch(() => {
          setIsAuthenticated(false);
          setToken("");
          setUser(null);
          navigate("/login");
        });
    }
  }, []);

  const value = { user, isAuthenticated, token, signIn, signOut };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
