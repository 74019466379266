import { Routes, Route } from "react-router-dom";
import SignIn from "./pages/SignIn";
import Header from "./components/Header";
import { RequireAuth, useAuth } from "./api/auth";
import Dashboard from "./pages/Dashboard";
import Beta from "./pages/Beta";
import Footer from "./components/Footer";
import SignUp from "./pages/SignUp";
import NewBucket from "./pages/bucket/NewBucket";
import Bucket from "./pages/bucket/Bucket";

function App() {
  const auth = useAuth();

  return (
    <div id="website" className="App">
      <Header />
      <div id="content">
        <Routes>
          <Route
            path="/"
            element={auth.isAuthenticated ? <Dashboard /> : <SignIn />}
          />
          <Route path="/login" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />

          <Route
            path="/beta"
            element={
              <RequireAuth>
                <Beta />
              </RequireAuth>
            }
          />

          <Route
            path="/new"
            element={
              <RequireAuth>
                <NewBucket />
              </RequireAuth>
            }
          />

          <Route
            path="/buckets/:name"
            element={
              <RequireAuth>
                <Bucket />
              </RequireAuth>
            }
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
