import { Card, Container } from "react-bootstrap";

const Beta = () => {
  return (
    <>
      <Container>
        <h1>Beta disclaimer</h1>
        <Card>
          <Card.Body>
            Wemint is under development and features will be coming along during
            the beta. <br />
            If you encounter a bug, if you wish to contact us, please do so by
            email:{" "}
            <a href="mailto:support@wemint.net" className="text-muted">
              support@wemint.net
            </a>
            <br />
            <br />
            Please understand there is no guarantee provided. <br />
            Use this service with caution, and have backups, at all times.
            <br />
            <br />
          </Card.Body>
        </Card>
        <br />
        <h1>New features</h1>
        <Card>
          <Card.Body>
            Since the launch, these features have been added:
            <br />
            - S3 support
            <br />
          </Card.Body>
        </Card>
        <br />
        <h1>Pricing</h1>
        <Card>
          <Card.Body>
            Wemint remains completely free during the beta.
            <br />
            <br />
            Once the beta is over, here's how we're gonna charge:
            <br />- minimum subscription will be €5 (pre-tax) per month with
            100GB included
            <br />- €0.05 (pre-tax) per gigabyte stored
            <br />
            <span className="text-decoration-underline">
              - we will not charge the bandwidth
            </span>
            <br />
            example 1: 50GB for 5€. example 2: 100GB for 5€. example 3: 200GB
            for 10€.
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default Beta;
