import React from "react";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useAuth } from "../api/auth";
import wemintLogo from "../components/assets/logo.svg";
import "../components/assets/Sign.scss";

/*
 const CreateTodo = () => {
   const mutation = useMutation(event => {
     event.preventDefault()
     return fetch('/api', new FormData(event.target))
   })
   return <form onSubmit={mutation.mutate}>...</form>
 }
*/

const SignIn = () => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const from = (location.state as any)?.from?.pathname || "/";

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      email: { value: string };
      password: { value: string };
    };

    auth.signIn(
      { email: target.email.value, password: target.password.value },
      () => {
        navigate(from, { replace: true });
      },
    );
  };

  return (
    <>
      <main className="form-signin text-center">
        <img
          className="mb-4 mt-5"
          src={wemintLogo}
          alt="Wemint logo"
          width=""
          height="72"
        />

        <Form onSubmit={handleSubmit}>
          <Form.Group className="form-floating">
            <Form.Control
              name="email"
              type="email"
              placeholder="name@example.com"
            />
            <Form.Label>Email address</Form.Label>
          </Form.Group>

          <Form.Group className="form-floating">
            <Form.Control
              name="password"
              type="password"
              placeholder="Password"
            />
            <Form.Label>Password</Form.Label>
          </Form.Group>
          <Button
            className="w-100 btn btn-lg btn-primary"
            variant="primary"
            type="submit">
            Sign in
          </Button>
        </Form>

        <p className="mt-4 text-muted small">
          <Link to="/signup" className="text-reset">
            create an account
          </Link>
          {/*{"    "}&nbsp;&nbsp;
          {"    "}&#8212;{"    "}&nbsp;&nbsp;
          {"    "}
          <Link to="/forgot" className="text-reset">
            lost password
  </Link>*/}
        </p>
      </main>
    </>
  );
};

export default SignIn;
