import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useNewBucket } from "../../api/api";

const NewBucket = () => {
  const navigate = useNavigate();
  const mutation = useNewBucket();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      name: { value: string };
      serviceLocationID: { value: string };
    };

    mutation.mutateAsync(
      {
        name: target.name.value,
        //serviceLocationID: parseInt(target.serviceLocationID.value),
        serviceLocationID: 1, // TODO: add locations
      },
      {
        onSuccess: data => {
          navigate(`/buckets/${data}`);
        },
      },
    );
  };

  return (
    <>
      <Container>
        <Form onSubmit={handleSubmit}>
          <p>
            <h1 className="thin-4 px-3 py-3 pt-md-2 pb-md-4 mx-auto text-center">
              Create your bucket
            </h1>
            <div className="px-3 py-3 pt-md-2 pb-md-4 mx-auto text-center text-muted">
              <Link to="/beta" className="text-muted text-sm">
                Beta: please read.
              </Link>
            </div>
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <Form.Group className="form-floating">
                  <Form.Control name="name" type="text" placeholder="bucket" />
                  <Form.Label>Bucket name</Form.Label>
                </Form.Group>
              </Col>
            </Row>
          </p>

          {/* <p>
          <h1 className="thin-5 px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
            Pick a location
          </h1>

          <Row className="center">
            <Col sm="3">
              <Card className="mt-1 mb-1">
                <Button variant="light" active={true}>
                  France
                </Button>
              </Card>
            </Col>
            <Col sm="3">
              <Card className="mt-1 mb-1">
                <Button variant="light" className="disabled">
                  The Netherlands (soon)
                </Button>
              </Card>
            </Col>
          </Row>
        </p> */}

          <p className="mx-auto text-center">
            <Button type="submit" variant="outline-primary">
              Create my bucket
            </Button>
          </p>
        </Form>
      </Container>
    </>
  );
};

export default NewBucket;
