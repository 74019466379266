export interface ApiError {
  code: number;
  message: string;
}

export interface Bucket {
  name: string;
  serviceLocationID: number;
}

export interface BucketS3Key {
  id: number;
  accessKey: string;
  secretKey: string;
  permissions: BucketS3KeyPermissions;
}

export interface BucketS3KeyInput {
  permissions: BucketS3KeyPermissions;
}

export enum BucketS3KeyPermissions {
  ReadOnly = "readonly",
  ReadWrite = "readwrite",
}

export interface BucketInput {
  name: string;
  serviceLocationID: number;
}

export interface Location {
  id: number;
  location: string;
  name: string;
}

export interface SignInInput {
  email: string;
  password: string;
}

export interface SignUpInput {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  country: string;
  zip: string;
}

export interface Token {
  expire: string;
  token: string;
}

export interface UserInfo {
  uuid: string;
  email: string;
  fullName: string;
  address: string;
  city: string;
  country: string;
  zip: string;
}
