import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { useAuth } from "./auth";
import {
  ApiError,
  Bucket,
  BucketInput,
  BucketS3Key,
  BucketS3KeyInput,
  Location,
  Token,
  UserInfo,
} from "./models";

const API_URL = process.env.REACT_APP_API_URL;

const get = async <T>(url: string, token?: string): Promise<T> => {
  const response = await fetch(API_URL + url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });

  const json = await response.json();

  if (!response.ok) {
    throw json.error;
  }

  return json.data;
};

const post = async <T>(url: string, data: any, token?: string): Promise<T> => {
  let headers: HeadersInit = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers.Authorization = "Bearer " + token;
  }

  const response = await fetch(API_URL + url, {
    method: "POST",
    body: JSON.stringify(data),
    headers,
  });

  const json = await response.json();

  if (!response.ok) {
    throw json.error;
  }

  return json.data;
};

const apiDelete = async <T>(
  url: string,
  data: any,
  token: string,
): Promise<T> => {
  const response = await fetch(API_URL + url, {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });

  const json = await response.json();

  if (!response.ok) {
    throw json.error;
  }

  return json.data;
};

export const useLocations = (): UseQueryResult<Location[], ApiError> => {
  const auth = useAuth();

  return useQuery("locations", () => get("/locations", auth.token));
};

export const useSignIn = (): UseMutationResult<Token, ApiError> =>
  useMutation(signInInput => post("/signin", signInInput));

export const useSignUp = (): UseMutationResult<null, ApiError> =>
  useMutation(signUpInput => post("/signup", signUpInput));

export const useBuckets = (): UseQueryResult<Bucket[], ApiError> => {
  const auth = useAuth();

  return useQuery("buckets", () => get("/buckets", auth.token));
};

export const useNewBucket = (): UseMutationResult<
  string,
  ApiError,
  BucketInput
> => {
  const auth = useAuth();
  const queryClient = useQueryClient();

  return useMutation(
    (bucketInput: BucketInput) => post("/buckets", bucketInput, auth.token),
    {
      onSuccess: _ => {
        queryClient.invalidateQueries("buckets");
      },
    },
  );
};

export const useBucketS3Keys = (
  name: string,
): UseQueryResult<BucketS3Key[], ApiError> => {
  const auth = useAuth();

  return useQuery(["buckets", name, "s3"], () =>
    get(`/buckets/${name}/s3`, auth.token),
  );
};

export const useNewBucketS3Key = (
  name: string,
): UseMutationResult<BucketS3Key, ApiError, BucketS3KeyInput> => {
  const auth = useAuth();
  const queryClient = useQueryClient();

  return useMutation(
    (bucketS3KeyInput: BucketS3KeyInput) =>
      post(`/buckets/${name}/s3`, bucketS3KeyInput, auth.token),
    {
      onSuccess: _ => {
        queryClient.invalidateQueries(["buckets", name, "s3"]);
      },
    },
  );
};

export const useBucketDeleteS3Key = (
  name: string,
): UseMutationResult<null, ApiError, number> => {
  const auth = useAuth();
  const queryClient = useQueryClient();

  return useMutation(
    (bucketS3KeyId: number) =>
      apiDelete(`/buckets/${name}/s3/${bucketS3KeyId}`, {}, auth.token),
    {
      onSuccess: _ => {
        queryClient.invalidateQueries(["buckets", name, "s3"]);
      },
    },
  );
};

export const getUser = (token: string): Promise<UserInfo> =>
  get("/user", token);

export const useUserInfo = (): UseQueryResult<UserInfo, ApiError> => {
  const auth = useAuth();

  return useQuery("user", () => getUser(auth.token));
};
