import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useBuckets } from "../api/api";
import Table from "react-bootstrap/Table";

const Dashboard = () => {
  const { isLoading, isError, data: buckets, error } = useBuckets();

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>An error has occurred: {error!.message}</div>;

  return (
    <>
      <Container>
        <h1>Buckets</h1>
        <div>
          <Link to="/new">New bucket</Link>
        </div>
        <hr />
        <Table hover borderless size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Objects</th>
              <th>Size</th>
              <th>Region</th>
              <th>Adm</th>
            </tr>
          </thead>
          <tbody>
            {buckets!.map((v, i) => (
              <tr className="border-top">
                <th key={i}>
                  <Link to={`/buckets/${v.name}`}>{v.name}</Link>
                </th>
                <th>0</th>
                <th>0B</th>
                <th>loc: {v.serviceLocationID}</th>
                <th>Edit</th>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default Dashboard;
